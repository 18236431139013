<template>
  <div>
    <div class="container">
      <div class="header-container">
        <header>
          <van-nav-bar
              :title="title"
              left-text="返回"
              right-text=""
              left-arrow
              @click-left="onBack"
          />
        </header>
      </div>
      <van-form @submit="onSubmit">
        <div class="section-box" style="padding: 10px">
          <van-row>
            <van-col span="24">
              <van-cell is-link :to="'../compulsive/bzRead?id='+xzid+'&isFooter=1&url='">
                <template #title>
                  <div>
                    <span style="margin-right: 10px;">业务办理须知</span>
                    <van-icon size="25" :name="require('../../assets/images/xz.png')" class="search-icon"/>
                  </div>
                </template>
              </van-cell>
            </van-col>
            <van-col span="7"></van-col>
            <van-col span="8"></van-col>
          </van-row>
          <van-checkbox style="padding: 5px;border: lightgrey solid 1px; border-radius: 10px;" v-model="isAgree"
                        shape="square">本人已认真阅读业务办理须知，并确认提供信息真实有效。
          </van-checkbox>
          <div v-if="isAgree">
            <div style="padding: 5px;line-height:25px">
              尊敬的客户！您办理的业务存在未出账的水量和垃圾费月度账单，客服人员受理您的业务后，会通知您待交纳的账单金额，请及时交清费用，以确保过户业务顺利完成。
            </div>
            <div><b>变更前信息</b></div>
            <div v-if="!transfer.arrears">
              <van-field
                  v-model="transfer.accountNo"
                  name="accountNo"
                  label="户      号 "
                  type="number"
                  placeholder="请填写户号"
                  @blur="blurAccount"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  v-model="transfer.oldAddress"
                  name="oldAddress"
                  label="用水地址"
                  readonly
                  border
                  colon
                  placeholder=""
              />
              <div class="account-error-msg" v-if="transfer.accountError" style="margin-top: 10px;">
                {{ transfer.accountErrorMsg }}
              </div>
            </div>
            <div v-if="!transfer.arrears && transfer.query">
              <div><b>变更后用户信息</b></div>
              <van-field
                  v-model="transfer.accountNo"
                  name="accountNo"
                  label="户号"
                  type="number"
                  readonly
                  border
                  colon
                  placeholder=""
              />
              <van-field
                  v-model="transfer.newName"
                  name="newName"
                  label="新户名"
                  placeholder="请填写新户名"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  v-model="transfer.newAddress"
                  name="newAddress"
                  label="产权地址"
                  placeholder="请填写产权地址"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field name="checkbox" label="">
                <template #input>
                  <van-checkbox v-model="transfer.isCheckAddress" shape="square" ><span style="color: red; margin-right: 5px;">*</span>确认产权证地址与用水地址一致</van-checkbox>
                </template>
              </van-field>
              <van-field
                  v-model="transfer.newPhone"
                  name="newPhone"
                  label="新联系电话"
                  placeholder="请填写新联系电话"
                  type="number"
                  border
                  colon
                  required
                  clearable
                  @blur="burlPhone"
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  readonly
                  clickable
                  name="zjType"
                  :value="transfer.zjType"
                  label="证件类型"
                  colon
                  required
                  placeholder="点击选择证件类型"
                  @click="showPicker = true"
              />
              <van-popup v-model="showPicker" position="bottom">
                <van-picker
                    show-toolbar
                    :columns="zjTypes"
                    @confirm="onzjTypeConfirm"
                    @cancel="showPicker = false"
                />
              </van-popup>
              <van-field
                  v-model="transfer.cid"
                  name="cid"
                  label="证件号码"
                  placeholder="请填写证件号码"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  v-model="transfer.cqzh"
                  name="cqzh"
                  label="产权证号"
                  placeholder="请填写产权证号"
                  border
                  colon
                  required
                  clearable
                  :rules="[{ required: true, message: '' }]"
              />
              <van-field
                  v-model="transfer.otherInfo"
                  name="otherInfo"
                  label="其他说明"
                  placeholder="请填写其他说明"
                  border
                  colon
                  rows="1"
                  autosize
                  type="textarea"
              />
              <van-field name="attachs" label-width="0" label="">
                <template #input>
                  <van-row>
                    <van-col span="24">
                      <span style="color: red;">*</span>
                      <span>有效身份证件正、反面（护照提供内页）</span>
                    </van-col>
                    <van-col span="24">
                      <van-cell is-link @click="getUploadDemo('0')">
                        <template #title>
                          <van-tag type="danger">上传示例</van-tag>
                        </template>
                      </van-cell>
                    </van-col>
                    <van-col span="24">
                      <UploadPic max-count="5" :upload-size="1024 * 1024 * 20" tag="1"
                                 @uploadSate="uploadSate"></UploadPic>
                    </van-col>
                  </van-row>
                </template>
              </van-field>
              <van-field name="attachs1" label-width="0">
                <template #input>
                  <van-row>
                    <van-col span="24">
                      <span style="color: red;">*</span>
                      <span>不动产证完整内页（或房产证、土地证、房产交易中心备案登记的房屋买卖合同之一）</span>
                    </van-col>
                    <van-col span="24">
                      <van-cell is-link @click="getUploadDemo('2')">
                        <template #title>
                          <van-tag type="danger">上传示例</van-tag>
                        </template>
                      </van-cell>
                    </van-col>
                    <van-col span="24">
                      <UploadPic max-count="5" :upload-size="1024 * 1024 * 20" tag="2"
                                 @uploadSate="uploadSate"></UploadPic>
                    </van-col>
                  </van-row>
                </template>
              </van-field>

              <van-field name="attachs2" label-width="0">
                <template #input>
                  <van-row>
                    <van-col span="24">
                      授权委托、经办人有效身份证件正、反面（护照提供内页）
                    </van-col>
                    <van-col span="24">
                      <UploadPic max-count="5" :upload-size="1024 * 1024 * 20" tag="3"
                                 @uploadSate="uploadSate"></UploadPic>
                    </van-col>
                  </van-row>
                </template>
              </van-field>
              <SmsCode :phone-number="transfer.newPhone" verify-code="0" @smsCodeSate="smsCodeSate"></SmsCode>
              <div style="margin: 16px;">
                <van-button type="info" round block>提交</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-form>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
    <PopUploadDemo :title="dialog.title" :show="dialog.show" :content="dialog.content"
                   @popClose="popClose"></PopUploadDemo>
  </div>
</template>
<script>
import Loading from "../../components/logding";
import {accountInfo} from "../../api/user";
import {getKey} from "../../utils/cookie";
import {uploadDemo} from "../../api/other";
import PopUploadDemo from "../../components/PopUploadDemo"
import UploadPic from "../../components/UploadPic";
import SmsCode from "../../components/SmsCode";
import {queryPersonalMobile, saveAccountTransfer} from "@/api/transfer";
import {Dialog} from "vant";

export default {
  name: "TransferAdd",
  components: {Loading, PopUploadDemo, UploadPic, SmsCode},
  data() {
    return {
      isShow: false,
      second: 5,
      xzid: this.$route.query.xzid,
      isAgree: false,
      lodingTxt: '加载中...',
      title: '居民过户',
      zjTypes: ['身份证', '军官证', '护照', '驾驶证', '回乡证'],
      showPicker: false,
      lastAccountNo: '',
      attachsFiles: [],
      attachsFiles1: [],
      attachsFiles2: [],
      transfer: {
        oldName: '',
        oldAddress: '',
        oldPhone: '',
        accountNo: '',
        arrears: false,
        query: false,
        accountError: false,
        accountErrorMsg: '',
        newName: '',
        newAddress: '',
        newPhone: '',
        zjType: '',
        cid: '',
        cqzh: '',
        otherInfo: '',
        smsCode: '',
        attachs: [],
        attachs1: [],
        attachs2: [],
        isCheckAddress: false
      },
      dialog: {
        show: false,
        title: '',
        content: ''
      },
      authenUsers: []
    }
  },
  created() {
    const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')
    if (userinfo && userinfo.account) {
      //获取证件类型
      this.transfer.zjType = userinfo.account.cardType == '居民身份证' ? '身份证' : userinfo.account.cardType
      //获取证件号码
      this.transfer.cid = userinfo.account.cardNumber
      //获取手机号码
      this.transfer.newPhone = userinfo.account.phone
      //获取姓名
      this.transfer.newName = userinfo.account.name
    }
    this.burlPhone()
  },
  methods: {
    /**
     * 提交
     */
    onSubmit() {
      const that = this
      //验证实名
      let isAuthen = false
      if (that.authenUsers.length > 0) {
        for (let i = 0; i < that.authenUsers.length; i++) {
          const item = that.authenUsers[i]
          if (item.idNum != that.transfer.cid) {
            isAuthen = true
            break;
          }
        }
      }
      if (isAuthen) {
        this.$toast('您填写的新联系电话与实名信息不符合')
        return
      }
      if (!that.transfer.isCheckAddress){
        this.$toast('请确认产权证地址与用水地址一致')
        return
      }
      console.log(that.transfer)
      if (!that.transfer.zjType || that.transfer.zjType == '') {
        this.$toast('请选择证件类型')
        return
      }
      if (that.transfer.attachs.length < 1) {
        this.$toast('请上传有效身份证件')
        return
      }
      if (that.transfer.attachs1.length < 1) {
        this.$toast('请上传不动产证件')
        return
      }
      that.lodingTxt = '正在保存...'
      that.isShow = true
      saveAccountTransfer(that.transfer).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          Dialog.alert({
            title: '温馨提示',
            message: response.message,
          }).then(() => {
            that.$router.go(-2)
          });
        } else {
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })

    },
    onBack: function () {
      this.$router.go(-2);
    },
    /**
     * 查询户号信息
     */
    blurAccount() {
      const that = this
      if (that.transfer.accountNo == '') return;
      if (that.transfer.accountNo == that.lastAccountNo) return;
      that.lastAccountNo = that.transfer.accountNo
      that.isShow = true
      that.lodingTxt = '正在查询户号...'
      accountInfo(that.transfer.accountNo).then(response => {
        console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          const data = response.data
          that.transfer.oldAddress = data.userAdd
          //that.transfer.newAddress = data.C013
          that.transfer.oldName = data.userName
          that.transfer.accountError = false
          that.transfer.accountErrorMsg = ''
          that.transfer.query = true
          /*if (data.C008 > 0) {
            that.transfer.accountError = true
            that.transfer.accountErrorMsg = '该用户存在欠费，不能过户。请结清水费后再来办理！'
          } else {
            that.transfer.accountError = false
            that.transfer.accountErrorMsg = ''
            that.transfer.query = true
          }*/
        } else {
          that.$toast(response.message)
          that.transfer.accountError = true
          that.transfer.accountErrorMsg = response.message
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
        that.transfer.accountError = true
        that.transfer.accountErrorMsg = '服务器错误，请稍后再试！'
      })
    },
    smsCodeSate(code) {
      console.log('获取短信验证码:' + code)
      this.transfer.smsCode = code
    },
    /**
     * 证件类型选中
     * @param e
     */
    onzjTypeConfirm(e) {
      const oldType = this.transfer.zjType
      this.transfer.zjType = e
      this.showPicker = false
      if (oldType != e) {
        this.transfer.cid = ''
      }
    },
    /**
     * 上传状态回调
     */
    uploadSate(obj) {
      const tag = parseInt(obj.tag)
      const uploadDates = obj.uploadDates
      switch (tag) {
          //有效身份证件
        case 1:
          this.transfer.attachs = []
          uploadDates.forEach(item => {
            this.transfer.attachs.push(item.id)
          });

          break
          //不动产证件
        case 2:
          this.transfer.attachs1 = []
          uploadDates.forEach(item => {
            this.transfer.attachs1.push(item.id)
          });
          break;
          //代理人、委托人证件
        case 3:
          this.transfer.attachs2 = []
          uploadDates.forEach(item => {
            this.transfer.attachs2.push(item.id)
          });
          break
      }
      //console.log('得到上传文件状态')
      console.log(this.transfer)
    },
    burlPhone() {
      const that = this
      if (that.transfer.newPhone && that.transfer.newPhone != '') {
        that.lodingTxt = '加载中...'
        that.isShow = false
        queryPersonalMobile(that.transfer.newPhone).then(response => {
          console.log(response)
          that.isShow = false
          that.authenUsers = []
          if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
            response.data.forEach(item => {
              that.authenUsers.push({idNum: item.idNum});
            })
          }
        }).catch(() => {
          that.authenUsers = []
          that.isShow = false
        });
      }
    },
    /**
     * 获取上传示例
     * @param key
     * @param title
     */
    getUploadDemo(e) {
      const that = this
      that.isShow = true
      that.lodingTxt = '加载中...'
      uploadDemo(e).then(response => {
        console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          const data = response.data
          that.dialog.title = data.infoTitle
          that.dialog.show = true
          that.dialog.content = data.infoContent
        } else {
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    popClose() {
      this.dialog.show = false
    }
  }
}
</script>

<style scoped>
.account-error-msg {
  text-align: center;
  color: red;
  font-weight: bold;
}
</style>